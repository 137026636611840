<template>
  <div class="detail">
    <!-- 导航栏 -->
    <Navbar :title="'PRODUCT'"></Navbar>
    <div class="top-box">
      <img :src="detail.img ? require('../../../assets' + detail.img) : {}" />
      <div>
        <p>{{ detail.title }}</p>
        <span>{{ detail.price }}</span>
        <button>Sold Out</button>
      </div>
    </div>

    <div class="bottom-box">
      <div class="cell title">Product Sp ecifications</div>
      <div class="cell" v-for="item in detail.details" :key="item">
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
export default {
  data () {
    return {
      detail: {}
    }
  },
  components: {
    Navbar
  },
  mounted () {
    this.detail = this.$route.query.detail
    
  },
  created () {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.detail {
  font-family: "PingFang-Regular";
  width: 100%;

  .top-box {
    background-color: #fff;
    margin-bottom: 10px;
    img {
      height: 600px;
      width: 100%;
    }
    div {
      padding: 10px 30px 20px;
      p {
        font-size: 24px;
        color: #000;
      }
      span {
        font-size: 36px;
        font-family: "PingFang-Medium";
        color: rgb(255, 0, 0);
      }
      button {
        float: right;
        width: 150px;
        height: 70px;
        border: none;
        border-radius: 10px;
        background-color: rgb(218, 218, 218);
        font-size: 30px;
        margin-bottom: 5px;
        color: rgb(114, 114, 114);
      }
    }
  }
  .bottom-box {
    background-color: #fff;
    .cell {
      box-sizing: border-box;
      width: 100% !important;
      line-height: 71px;
      padding: 0 30px;
      border-bottom: 1px solid #e8e8e8;
      font-size: 24px;
      color: #000;
    }
    .title {
      font-size: 30px;
      color: rgb(116, 116, 116);
    }
  }
}
</style>
